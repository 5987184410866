import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input, type OnInit, ViewEncapsulation, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { type TableConfig } from '@core/models/table';
import { EnumService } from '@core/services/enum.service';
import { ModalService } from '@core/services/modal.service';
import { type UserPermissions } from '@core/services/permission.service';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';
import { RadioComponent } from '@design/forms/radio/radio.component';

import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { TableComponent } from '../../../core/components/table/table.component';
import { UserLogoComponent } from '../../../core/components/user-logo/user-logo.component';
import { type CompanyContactSearchModel } from '../../models/contact';
import { UserInvitationSource } from '../../models/invitation';
import { NetworkService } from '../../network.service';
import { UserInvidedModalComponent } from '../user-invided-modal/user-invided-modal.component';

@UntilDestroy()
@Component({
  selector: 'contact-selector-modal',
  templateUrl: './contact-selector-modal.component.html',
  styleUrls: ['./contact-selector-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    TableComponent,
    UserLogoComponent,
    RadioComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class ContactSelectorModalComponent implements OnInit {
  private readonly networkService = inject(NetworkService);
  private readonly toastr = inject(ToastrService);
  private readonly modalService = inject(ModalService);
  private readonly userService = inject(UserService);
  public readonly activeModal = inject(NgbActiveModal);
  private readonly enumService = inject(EnumService);
  private readonly cdr = inject(ChangeDetectorRef);
  @Input() hideNewUserBtn = false;
  @Input() preSelectedContacts: CompanyContactSearchModel[] = [];
  @Input() isAssigningTask: boolean;
  @Input() companyId: number;
  @Input() multiselect = false;

  public isLoading = false;

  public selectedContacts: CompanyContactSearchModel[] = [];
  public contacts: CompanyContactSearchModel[] = [];

  public steps = {
    contactSelection: 0,
    contactCreation: 1,
  };

  public currentStep = 0;

  public contactForm: UntypedFormGroup;
  public tableConfig: TableConfig;

  constructor() {
    this.contactForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      workEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
  }

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public ngOnInit(): void {
    this.selectedContacts = this.preSelectedContacts || [];

    let loadUrl: string;
    if (this.isAssigningTask) {
      loadUrl = `api/companies/my/contacts/search?Statuses=Active&Statuses=Invited&AssociatedUserIdsToExclude=${this.userService.userProfile.id}`;
    } else {
      if (this.companyId) {
        loadUrl = `api/companies/my/contacts/${this.companyId}/search?Statuses=Active&Statuses=Invited`;
      } else {
        loadUrl = `api/companies/my/contacts/search?Statuses=Active&Statuses=Invited`;
      }
    }

    const columns = [
      {
        name: 'name',
        label: 'common.strings.name',
        sortable: true,
        minWidth: '200px',
        maxWidth: '1.5fr',
      },
      {
        name: 'location',
        label: 'companyContacts.location',
        sortable: true,
        minWidth: '150px',
        maxWidth: '1fr',
      },
      {
        name: 'email',
        label: 'common.strings.email',
        sortable: true,
        minWidth: '200px',
        maxWidth: '1.5fr',
      },
    ];

    if (!this.multiselect) {
      columns.unshift({
        name: 'select',
        label: 'common.buttons.select',
        sortable: false,
        minWidth: '70px',
        maxWidth: '70px',
      });
    }

    const filters = [];

    if (this.multiselect) {
      filters.push({
        name: 'department',
        queryParam: 'department',
        defaultValue: '',
        options: [
          {
            label: 'contactSelector.buttons.allDepartments',
            value: '',
          },
        ],
      });

      this.enumService
        .getCommunicationRoles()
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          res.forEach((dep) => {
            filters[0].options.push({
              label: dep.title,
              value: dep.key,
            });
          });
        });
    }

    this.tableConfig = {
      loadUrl,
      columns,
      selectable: this.multiselect,
      hideControls: !this.multiselect,
      filters,
      compareFunc: (x, y) => {
        return x.associatedUserId === y.associatedUserId;
      },
    };
  }

  public inviteNewUser(): void {
    this.currentStep = this.steps.contactCreation;
  }

  public backToList(): void {
    this.currentStep = this.steps.contactSelection;
  }

  public selectContact(contact: CompanyContactSearchModel): void {
    this.selectedContacts = [contact];
  }

  public submitSelection(): void {
    this.activeModal.close({
      contacts: this.selectedContacts,
    });
  }

  public createContact(): void {
    if (this.contactForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.networkService
      .createContact({
        ...this.contactForm.value,
        source: this.isAssigningTask ? UserInvitationSource.TaskReassignment : UserInvitationSource.TaskCustomForm,
      })
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.activeModal.close({
            isNewContact: true,
            contacts: [{ contact: res }],
          });

          if (!this.isAssigningTask) {
            this.modalService.open({
              content: UserInvidedModalComponent,
              options: {
                size: 'sm',
              },
            });
          }
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err, {
            invalid_email_format: 'contactSelector.errors.invalidEmail',
          });
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
